import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/release-notes.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p {...{
      "className": "date"
    }}>{`8th December 2019`}</p>


    <h2 {...{
      "id": "-new-features",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-new-features",
        "aria-label": " new features permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🌟 New features`}</h2>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`We now have a new immutable cookie API with `}<inlineCode parentName="p">{`SameSite`}</inlineCode>{` attribute support, which replaces Netty's cookie API. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1567"
          }}>{`#1567`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2286"
          }}>{`#2286`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Cookie c1 = Cookie.of("foo", "bar")
Cookie c2 = Cookie.builder("alice", "bob")
                  .domain("foo.com")
                  .path("/bar")
                  .maxAge(3600)
                  .sameSite("Strict")
                  .build();
// Encoding
ResponseHeaders resHeaders =
    ResponseHeaders.builder(HttpStatus.OK)
                   .add(HttpHeaderNames.SET_COOKIE,
                        Cookie.toSetCookieHeaders(c1, c2))
                   .build();
// Decoding
Cookies cookies =
    Cookie.fromSetCookieHeaders(
        resHeaders.getAll(HttpHeaderNames.SET_COOKIE));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now replace the request headers of `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{` more conveniently using `}<inlineCode parentName="p">{`HttpRequest.withHeaders(RequestHeaders)`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2278"
          }}>{`#2278`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2283"
          }}>{`#2283`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`HttpRequest req = HttpRequest.of(HttpMethod.POST, "/post",
                                 MediaType.PLAIN_TEXT_UTF_8,
                                 "Hello!");
RequestHeaders newHeaders = RequestHeaders.of(HttpMethod.PUT, "/put",
                                              MediaType.PLAIN_TEXT_UTF_8);
HttpRequest newReq = req.withHeaders(newHeaders);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`ServiceRequestContext.blockingTaskExecutor()`}</inlineCode>{` is now a `}<inlineCode parentName="p">{`ScheduledExecutorService`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`ExecutorService`}</inlineCode>{`, which means you can schedule delayed or periodic tasks. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2269"
          }}>{`#2269`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`// A service that sends \`PING!\` every second.
HttpService service = (ctx, req) -> {
    HttpResponse res = HttpResponse.streaming();
    res.write(ResponseHeaders.of(200));
    AtomicReference<ScheduledFuture<?>> futureHolder = new AtomicReference<>();
    futureHolder.set(ctx.blockingTaskExecutor().scheduleWithFixedDelay(() -> {
        boolean success = res.tryWrite(HttpData.ofUtf8("PING!\\r\\n"));
        if (!success) {
            if (futureHolder.get() != null) {
                futureHolder.get().cancel(false);
            }
        }
    }, 1, TimeUnit.SECONDS));
    return res;
};
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now add converters and exception handlers to annotated services more conveniently using the new fluent builder methods. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2242"
          }}>{`#2242`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server server =
    Server.builder()
          .annotatedService().requestConverters(converterA, converterB)
                             .responseConverters(converterC, converterD)
                             .exceptionHandlers(handlerA, handlerB)
                             .build(myAnnotatedService)
          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now configure how Armeria decides `}<inlineCode parentName="p">{`ServiceRequestContext.clientAddress()`}</inlineCode>{` via `}<inlineCode parentName="p">{`ServerBuilder.clientAddressMapper()`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/1631"
          }}>{`#1631`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2294"
          }}>{`#2294`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Server.builder()
      .clientAddressMapper(proxiedAddrs -> {
          InetSocketAddress srcAddr = proxiedAddrs.sourceAddress();
          List<InetSocketAddress> destAddrs = proxiedAddrs.destinationAddresses();
          if (destAddrs.isEmpty()) {
              // No proxy servers involved.
              return srcAddr;
          } else {
              // When there are more than one proxy server involved,
              // trust only the address given by the last proxy server.
              return destAddrs.get(destAddrs.size() - 1);
          }
      });
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can now choose a different SLF4J `}<inlineCode parentName="p">{`Logger`}</inlineCode>{` than the default one when using `}<inlineCode parentName="p">{`LoggingClient`}</inlineCode>{` or `}<inlineCode parentName="p">{`LoggingService`}</inlineCode>{`. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2220"
          }}>{`#2220`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2237"
          }}>{`#2237`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`Logger myLogger = LoggerFactory.getLogger(MyService.class);
Server server =
    Server.builder()
          .service("/",
                   myService.decorate(LoggingService.builder()
                                                    .logger(myLogger)
                                                    .newDecorator()))
          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`BraveClient`}</inlineCode>{` now adds connection timing information to a span. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2271"
          }}>{`#2271`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2273"
          }}>{`#2273`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`connection-acquire.start`}</inlineCode>{` and `}<inlineCode parentName="li">{`connection-acquire.end`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`dns-resolve.start`}</inlineCode>{` and `}<inlineCode parentName="li">{`dns-resolve.end`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`socket-connect.start`}</inlineCode>{` and `}<inlineCode parentName="li">{`socket-connect.end`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`connection-reuse.start`}</inlineCode>{` and `}<inlineCode parentName="li">{`connection-reuse.end`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`withDeadlineAfter`}</inlineCode>{` is now supported for gRPC client stubs. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2284"
          }}>{`#2284`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`GrpcService`}</inlineCode>{` now allows controlling whether to respect the `}<inlineCode parentName="p">{`"grpc-timeout"`}</inlineCode>{` header sent by a gRPC client. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2284"
          }}>{`#2284`}</a></p>
        <ul parentName="li">
          <li parentName="ul">{`Consider disabling this feature via `}<inlineCode parentName="li">{`GrpcServiceBuilder.useClientTimeoutHeader()`}</inlineCode>{` in an insecure environment.`}</li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`THttpService`}</inlineCode>{` and `}<inlineCode parentName="p">{`ThriftCallService`}</inlineCode>{` now supports adding more than one Thrift service at the same endpoint. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2164"
          }}>{`#2164`}</a>{` `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2285"
          }}>{`#2285`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`class MyFooService implements FooService.Iface ... {
    public void foo() { ... }
}

class MyBarService implements BarService.Iface ... {
    public void bar() { ... }
}

// "foo" call goes to MyFooService and
// "bar" call goes to MyBarService.
Server server =
    Server.builder()
          .service("/thrift",
                   THttpService.builder()
                               .addService(new MyFooService())
                               .addService(new MyBarService())
                               .build())
          .build();
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`armeria-spring-boot-actuator-autoconfigure`}</inlineCode>{` now supports the official CORS settings. `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria/issues/2063"
          }}>{`#2063`}</a></p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-yaml"
          }}>{`# application.yml
management.endpoints.web.cors.allowed-origins: https://example.com
management.endpoints.web.cors.allowed-methods: GET,POST
`}</code></pre>
      </li>
    </ul>
    <h2 {...{
      "id": "-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-improvements",
        "aria-label": " improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`📈 Improvements`}</h2>
    <ul>
      <li parentName="ul">{`Improved routing performance. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2293"
        }}>{`#2293`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2295"
        }}>{`#2295`}</a></li>
      <li parentName="ul">{`The core JAR file is now smaller, by removing unnecessary resources such as web fonts and favicons. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2299"
        }}>{`#2299`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2300"
        }}>{`#2300`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`HttpRequest.of(RequestHeaders, Publisher)`}</inlineCode>{` now handles the case where the `}<inlineCode parentName="li">{`Publisher`}</inlineCode>{` is actually an `}<inlineCode parentName="li">{`HttpRequest`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2278"
        }}>{`#2278`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2283"
        }}>{`#2283`}</a></li>
    </ul>
    <h2 {...{
      "id": "-security-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-security-fixes",
        "aria-label": " security fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🔒 Security fixes`}</h2>
    <ul>
      <li parentName="ul">{`(Moderate) `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/security/advisories/GHSA-35fr-h7jr-hh86"
        }}>{`CWE-113: Improper Neutralization of CRLF Sequences in HTTP Headers ('HTTP Response Splitting')`}</a></li>
      <li parentName="ul">{`(Low) `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/security/advisories/GHSA-24r8-fm9r-cpj2"
        }}>{`Multiple timing attack vulnerabilities leading to the recovery of secrets based on the use of non-constant time compare function`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-bug-fixes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-bug-fixes",
        "aria-label": "️ bug fixes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🛠️ Bug fixes`}</h2>
    <ul>
      <li parentName="ul">{`The event loop threads are properly cleaned up when `}<inlineCode parentName="li">{`Server`}</inlineCode>{` failed to start. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2288"
        }}>{`#2288`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Server`}</inlineCode>{` now sends a redirect response correctly for the requests without a trailing slash (`}<inlineCode parentName="li">{`/`}</inlineCode>{`) even when you bound a fallback service at `}<inlineCode parentName="li">{`prefix:/`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2116"
        }}>{`#2116`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2292"
        }}>{`#2292`}</a></li>
      <li parentName="ul">{`Fixed a bug where routing cache did not work as expected, leading suboptimal routing performance. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2293"
        }}>{`#2293`}</a></li>
      <li parentName="ul">{`Annotated services now accept only the first `}<inlineCode parentName="li">{`"Cookie"`}</inlineCode>{` header when injecting `}<inlineCode parentName="li">{`Cookies`}</inlineCode>{`, because multiple `}<inlineCode parentName="li">{`"Cookie"`}</inlineCode>{` headers must be prohibited according to RFC 6265. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2286"
        }}>{`#2286`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`GrpcService`}</inlineCode>{` now respects gRPC stub deadline. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2008"
        }}>{`#2008`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2284"
        }}>{`#2284`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-deprecations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-deprecations",
        "aria-label": "️ deprecations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🏚️ Deprecations`}</h2>
    <ul>
      <li parentName="ul">{`Deprecated `}<inlineCode parentName="li">{`HttpRequest.of(HttpRequest, RequestHeaders)`}</inlineCode>{` in favor of
`}<inlineCode parentName="li">{`HttpRequest.withHeaders()`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2283"
        }}>{`#2283`}</a></li>
      <li parentName="ul">{`Deprecated `}<inlineCode parentName="li">{`HttpRequest.of(AggregatedHttpRequest)`}</inlineCode>{` and
`}<inlineCode parentName="li">{`HttpResponse.of(AggregatedHttpResponse)`}</inlineCode>{` in favor of
`}<inlineCode parentName="li">{`AggregatedHttpRequest.toHttpRequest()`}</inlineCode>{` and
`}<inlineCode parentName="li">{`AggregatedHttpResponse.toHttpResponse()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2283"
        }}>{`#2283`}</a>
        <ul parentName="li">
          <li parentName="ul">{`These methods were removed from `}<inlineCode parentName="li">{`AggregatedHttpMessage`}</inlineCode>{` some time ago due to ambiguity, but now we are reviving them because we have separate types for requests and responses.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`Cookies.copyOf()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`Cookies.of()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2286"
        }}>{`#2286`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`AnnotatedServiceBuildingBuilder.requestConverter()`}</inlineCode>{`, `}<inlineCode parentName="li">{`responseConverter()`}</inlineCode>{` and `}<inlineCode parentName="li">{`exceptionHandler()`}</inlineCode>{` have been deprecated in favor of `}<inlineCode parentName="li">{`requestConverters()`}</inlineCode>{`, `}<inlineCode parentName="li">{`responseConverters()`}</inlineCode>{` and `}<inlineCode parentName="li">{`exceptionHandlers()`}</inlineCode>{` respectively. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2242"
        }}>{`#2242`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Sampler.rateLimited()`}</inlineCode>{` has been deprecated in favor of `}<inlineCode parentName="li">{`rateLimiting()`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2122"
        }}>{`#2122`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2289"
        }}>{`#2289`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`THttpService.of()`}</inlineCode>{` and `}<inlineCode parentName="li">{`ofFormats()`}</inlineCode>{` factory methods that require a `}<inlineCode parentName="li">{`Map<String, ?>`}</inlineCode>{` have been deprecated. Use `}<inlineCode parentName="li">{`THttpService.builder()`}</inlineCode>{` and call `}<inlineCode parentName="li">{`THttpService.addService(String, Object)`}</inlineCode>{` multiple times instead. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2285"
        }}>{`#2285`}</a></li>
    </ul>
    <h2 {...{
      "id": "️-breaking-changes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%EF%B8%8F-breaking-changes",
        "aria-label": "️ breaking changes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`☢️ Breaking changes`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`ServerBuilder.blockingTaskExecutor()`}</inlineCode>{` now requires a `}<inlineCode parentName="li">{`ScheduledExecutorService`}</inlineCode>{` instead of an `}<inlineCode parentName="li">{`ExecutorService`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2269"
        }}>{`#2269`}</a></li>
      <li parentName="ul">{`The behavior of automatic redirection for the case of missing trailing slash (`}<inlineCode parentName="li">{`/`}</inlineCode>{`) has been changed (in a good way). `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2116"
        }}>{`#2116`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2292"
        }}>{`#2292`}</a>
        <ul parentName="li">
          <li parentName="ul">{`For example, previously, when a client sent a request to `}<inlineCode parentName="li">{`/docs`}</inlineCode>{`, the following server routed the request to `}<inlineCode parentName="li">{`TomcatService`}</inlineCode>{`:`}
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-java"
              }}>{`Server server =
    Server.builder()
          .serviceUnder("/docs", new DocService())
          .serviceUnder("/", TomcatSerivce.forClassPath(...))
          .build();
`}</code></pre>
          </li>
          <li parentName="ul">{`However, from this release, the request will not be routed to `}<inlineCode parentName="li">{`TomcatService`}</inlineCode>{` but the server will issue a redirect response to `}<inlineCode parentName="li">{`/docs/`}</inlineCode>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ProxiedAddresses`}</inlineCode>{` class has been redesigned to handle the case of more than one intermediary proxy server. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1631"
        }}>{`#1631`}</a>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2294"
        }}>{`#2294`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Cookies`}</inlineCode>{` now uses Armeria's own `}<inlineCode parentName="li">{`Cookie`}</inlineCode>{`. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2286"
        }}>{`#2286`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`Cookies`}</inlineCode>{` now may contain the cookies with the same name. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2286"
        }}>{`#2286`}</a>
        <ul parentName="li">
          <li parentName="ul">{`Note that RFC does not prohibit duplicate names at all, so this is correct behavior.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Annotated services now accept only the first `}<inlineCode parentName="li">{`"Cookie"`}</inlineCode>{` header when decoding cookies. Sending multiple `}<inlineCode parentName="li">{`"Cookie"`}</inlineCode>{` headers was a violation of RFC 6265 anyway. `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2286"
        }}>{`#2286`}</a></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`ThriftCallService`}</inlineCode>{` factory method with signature `}<inlineCode parentName="li">{`of(Map<String, ? extends Iterable<?>>)`}</inlineCode>{` has been changed to `}<inlineCode parentName="li">{`of(Map<String, ? extends Iterable<?>>)`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2285"
        }}>{`#2285`}</a></li>
    </ul>
    <h2 {...{
      "id": "-dependencies",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-dependencies",
        "aria-label": " dependencies permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`⛓ Dependencies`}</h2>
    <ul>
      <li parentName="ul">{`Brave 1.3.1`}</li>
      <li parentName="ul">{`Jetty 9.4.22 -> 9.4.24`}</li>
      <li parentName="ul">{`Micrometer 1.3.1 -> 1.3.2`}</li>
      <li parentName="ul">{`Netty TCNative BoringSSL 2.0.27 -> 2.0.26`}</li>
      <li parentName="ul">{`Project Reactor 3.3.0 -> 3.3.1`}</li>
      <li parentName="ul">{`RxJava2 2.2.14 -> 2.2.15`}</li>
      <li parentName="ul">{`Spring Boot 2.1.10 -> 2.2.1`}</li>
      <li parentName="ul">{`Tomcat 9.0.27 -> 9.0.29, 8.5.47 -> 8.5.49`}</li>
    </ul>
    <h2 {...{
      "id": "-thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#-thank-you",
        "aria-label": " thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`🙇 Thank you`}</h2>
    <ThankYou usernames={['anuraaga', 'chrsow', 'heowc', 'hyangtack', 'ikhoon', 'imasahiro', 'JLLeitschuh', 'JunoJunho', 'magnuschatt', 'minwoox', 'monorisk', 'renaudb', 'sivaalli', 'stypr', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      